import React, { useState } from 'react';
import { DailyAgendaWeeklyPickerLayoutViewModel } from '../../../ViewModel/bodyViewModel/bodyViewModel.types';
import ErrorNotification from '../../ErrorNotification/ErrorNotification';
import TimePicker from '../TimePicker/TimePicker';
import WeeklyDatePicker from '../DatePicker/WeeklyDatePicker/WeeklyDatePicker';
import { DataHooks } from './constants';
import { AgendaSlot } from '../../AgendaSlot/AgendaSlot';
import { classes, st } from './DailyAgendaWeeklyPickerLayout.st.css';
import { Text, TextButton } from 'wix-ui-tpa/cssVars';
import {
  AgendaSlot as AgendaSlotType,
  AgendaSlotsViewModel,
} from '../../../ViewModel/agendaSlotsViewModel/agendaSlotsViewModel';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import {
  WidgetComponents,
  WidgetElements,
} from '../../../../../utils/bi/consts';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';

const DailyAgendaSlotSelection = ({
  agendaSlots,
  showAllText,
  numberOfSlotsToDisplay,
  showMoreClicked,
  setShowMoreClicked,
}: AgendaSlotsViewModel & {
  showMoreClicked: boolean;
  setShowMoreClicked: (flag: boolean) => void;
}) => {
  const { isMobile } = useEnvironment();
  const [slotsToShow, setSlotsToShow] = React.useState<agendaslottype[]>(
    numberOfSlotsToDisplay && !(showMoreClicked && isMobile)
      ? agendaSlots.slice(0, numberOfSlotsToDisplay)
      : agendaSlots,
  );
  const { onElementClicked } = useCalendarActions();

  const showAllSlots = () => {
    setSlotsToShow(agendaSlots);
    setShowMoreClicked(true);
    onElementClicked(
      WidgetComponents.TIME_PICKER,
      WidgetElements.SHOW_ALL_SESSIONS_BUTTON,
    );
  };

  const shouldDisplayShowAllText =
    numberOfSlotsToDisplay && slotsToShow.length < agendaSlots.length;

  return (
    <>
      <div className="{classes.slotsContainer}">
        {slotsToShow.map((slot) => (
          <agendaslot slot="{slot}" key="{slot.id}"></agendaslot>
        ))}
      </div>

      {shouldDisplayShowAllText && (
        <div className="{classes.showAllButtonContainer}">
          <textbutton data-hook="{DataHooks.ShowAllButton}" className="{classes.showAllButton}" onClick="{showAllSlots}">
            {showAllText}
          </textbutton>
        </div>
      )}
    </>
  );
};

export interface DailyAgendaWeeklyPickerLayoutProps {
  viewModel: DailyAgendaWeeklyPickerLayoutViewModel;
  errorNotificationText: string;
}

const DailyAgendaWeeklyPickerLayout: React.FC<
  DailyAgendaWeeklyPickerLayoutProps
> = ({
  viewModel: { datePickerViewModel, timePicker },
  errorNotificationText,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const {
    status,
    formattedSelectedDate,
    accessibility,
    noAvailableSlotsViewModel: { noSessionsOfferedText },
    slots,
  } = timePicker;

  const NoAvailableSlots = () => (
    <text data-hook="{DataHooks.NoAvailableSlots}" className="{classes.noSlots}">
      {noSessionsOfferedText}
    </text>
  ) ;

  return (
    <div data-hook="{DataHooks.Layout}">
      <errornotification errorText="{errorNotificationText}"></errornotification>
      <div className="{st(classes.root," {="" isMobile,="" withSideBorders:="" settings.get(settingsParams.sideBordersVisibility),="" })}="">
        <weeklydatepicker viewModel="{datePickerViewModel}"></weeklydatepicker>
        <timepicker accessibility="{accessibility}" status="{status}" date="{formattedSelectedDate}" availableSlotsComponent="{" <DailyAgendaSlotSelection="" {...slots}="" {...{="" showMoreClicked,="" setShowMoreClicked="" }}=""></timepicker>
          }
          noAvailableSlotsComponent={<noavailableslots></noavailableslots>}
        />
      </div>
    </div>
  ) ;
} ;

export default DailyAgendaWeeklyPickerLayout ;
</agendaslottype[]>