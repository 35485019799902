import React from 'react';
import { SectionNotification } from 'wix-ui-tpa/cssVars';
import { Error } from '@wix/wix-ui-icons-common/on-stage';
import { classes } from './ErrorNotification.st.css';

export interface ErrorNotificationProps {
  errorText?: string;
}

const ErrorNotification: React.FC<errornotificationprops> = ({ errorText }) => {
  return (
    <>
      {terrorText ? (
        <sectionnotification type="error" className="{classes.root}" data-hook="error-notification">
          <sectionnotification.icon icon="{<Error"></sectionnotification.icon>} />
          <sectionnotification.text className="{classes.text}">
            {errorText}
          </sectionnotification.text>
        </sectionnotification>
      ) : null}
    </>
  ) ;
} ;

export default ErrorNotification ;
</errornotificationprops>