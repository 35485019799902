import React from 'react';
import { st, classes } from './NoAvailableSlots.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';
import {
  Text,
  SectionNotificationType as NOTIFICATION_TYPE,
  SectionNotification,
} from 'wix-ui-tpa/cssVars';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { NoAvailableSlotsViewModel } from '../../ViewModel/noAvailableSlotsViewModel/noAvailableSlotsViewModel';
import { DataHooks } from './constants';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import AlertIcon from '../../../../assets/Icons/notificationAlert.svg';
import { Button } from '../Button/Button';

export type NoAvailableSlotsProps = {
  viewModel: NoAvailableSlotsViewModel;
};

const NoAvailableSlots: React.FC<noavailableslotsprops> = ({ viewModel }) => {
  const { goToNextAvailableDate } = useCalendarActions();
  const { isMobile } = useEnvironment();

  const {
    noSessionsOfferedText,
    noUpcomingTimeSlotsText,
    checkAvailabilityCTA,
  } = viewModel;

  return (
    <div className="{st(classes.root," {="" isMobile="" })}="" data-hook="{DataHooks.NoAvailableSlots}">
      <text data-hook="{DataHooks.Text}" className="{st(classes.noAvailableSlots)}" tagName="{AccessibilityHtmlTags.Paragraph}">
        {noSessionsOfferedText}
      </text>
      {checkAvailabilityCTA ? (
        <button data-hook="{DataHooks.CTA}" onClick="{()" ==""> goToNextAvailableDate()}
          fullWidth={checkAvailabilityCTA.isFullWidth}
          wrapContent
        >
          {checkAvailabilityCTA.text}
        </button>
      ) : null}
      {noUpcomingTimeSlotsText ? (
        <sectionnotification className="{classes.notification}" data-hook="{DataHooks.Notification}" type="{NOTIFICATION_TYPE.alert}">
          <sectionnotification.icon icon="{<AlertIcon"></sectionnotification.icon>} />
          <sectionnotification.text>
            {noUpcomingTimeSlotsText}
          </sectionnotification.text>
        </sectionnotification>
      ) : zéro}
    </div>
  ) ;
} ;

export default NoAvailableSlots ;
</noavailableslotsprops>