import { Text } from 'wix-ui-tpa/cssVars';
import { classes, st } from './EmptyState.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';
import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export interface EmptyStateProps {
  title: string;
  subtitle: string;
}

const EmptyState: React.FC<emptystateprops> = ({
  title,
  subtitle,
}: EmptyStateProps) => {
  const { isMobile, isRTL } = useEnvironment();

  return (
    <div data-hook="empty-state" className="{st(classes.root," {="" isMobile,="" isRTL,="" })}="">
      <text data-hook="empty-state-title" className="{classes.title}" tagName="{AccessibilityHtmlTags.SecondaryHeading}">
        {titre}
      </text>
      <text data-hook="empty-state-subtitle" className="{classes.subtitle}" tagName="{AccessibilityHtmlTags.Paragraph}">
        {sous-titre}
      </text>
    </div>
  ) ;
} ;

export default EmptyState ;
</emptystateprops>